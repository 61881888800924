<section class="dashboard-body" [ngClass]="menuClassDeatails()">
  <div class="container-fluid">
    <div fxLayout="column" fxFlex="100%" class="">
      <div class="top-col">
        <h5 class="head-text color-green">{{model['name']}}</h5>

      </div>
      <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex="100%" fxLayoutGap="10px">



          <div fxLayout="row" fxFlex="100%">
            <div fxFlex="25%" fxLayout="column">
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>Name</mat-label> 
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['name']}}</mat-label>
                </div>
              </div>

            </div>
            
            <div fxFlex="25%" fxLayout="column">
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>Language</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['refLanguage']}}</mat-label>
                </div>
              </div>

            </div>
            <div fxFlex="25%" fxLayout="column">
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>Location</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['refLocation']}}</mat-label>
                </div>
              </div>
            </div>
            <div fxFlex="25%" fxLayout="column">
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>Meeting ID	</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['meeting_id']}}</mat-label>
                </div>
              </div>

            </div>
            
            </div>
            <div fxLayout="row" fxFlex="100%">
              <div fxFlex="25%" fxLayout="column">
                <div fxLayout="row" fxFlex="100%">
                  <div fxFlex="30%" fxLayout="column">
                    <mat-label>Start Date</mat-label>
                  </div>
                  <div fxFlex="5%" fxLayout="column">
                    <mat-label>:</mat-label>
                  </div>
                  <div fxFlex="40%" fxLayout="column">
                    <mat-label>{{model['startTime'] | date:'mediumDate'}}</mat-label>
                  </div>
                </div>
  
              </div>
            <div fxFlex="25%" fxLayout="column">
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>Start Time</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['startTime'] | date:'shortTime'}}</mat-label>
                </div>
              </div>
            </div>
            <div fxFlex="25%" fxLayout="column">
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>End Time</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['endTime'] | date:'shortTime'}}</mat-label>
                </div>
              </div>
              
              <!-- <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>End Payment Time</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['endPaymentTime'] | date:'shortTime'}}</mat-label>
                </div>
              </div> -->

            </div>
             <div fxFlex="25%" fxLayout="column">
              <!-- <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>Actual Amount</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['actualAmount']}}</mat-label>
                </div>
              </div> -->
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>Payable Amount</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['payableAmount']}}</mat-label>
                </div>
              </div>
            </div> 
           
            </div>
            <div fxLayout="row" fxFlex="100%">
              <!-- <div fxFlex="25%" fxLayout="column">
                <div fxLayout="row" fxFlex="100%">
                  <div fxFlex="30%" fxLayout="column">
                    <mat-label>Discount Amount</mat-label>
                  </div>
                  <div fxFlex="5%" fxLayout="column">
                    <mat-label>:</mat-label>
                  </div>
                  <div fxFlex="40%" fxLayout="column">
                    <mat-label>{{model['discountAmount']}}</mat-label>
                  </div>
                </div>
  
              </div> -->
            <!-- <div fxFlex="25%" fxLayout="column">
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="30%" fxLayout="column">
                  <mat-label>Payable Amount</mat-label>
                </div>
                <div fxFlex="5%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="40%" fxLayout="column">
                  <mat-label>{{model['payableAmount']}}</mat-label>
                </div>
              </div>
            </div> -->
            <!-- <div fxFlex="50%" fxLayout="column">
              <div fxLayout="row" fxFlex="100%">
                <div fxFlex="15%" fxLayout="column">
                  <mat-label>Description</mat-label>
                </div>
                <div fxFlex="2%" fxLayout="column">
                  <mat-label>:</mat-label>
                </div>
                <div fxFlex="83%" fxLayout="column">
                  <mat-label>{{model['description']}}</mat-label>
                </div>
              </div>
            </div> -->
            
          </div>
          
        </div>

        <!-- <div fxLayout="row" fxFlex="100%">
          <div class="example-button-row" fxFlex="20%">
            <form [formGroup]="form">
              <formly-form [form]="form" [options]="options" [model]="model" [fields]="formFields">
              </formly-form>
            </form>
          </div>
          <div fxFlex=10%>
            <button style="margin-top: 15px;" mat-stroked-button (click)="filterPayment()">Apply</button>
          </div>
          <div fxFlex="70%" fxLayoutAlign="end">
          
            <div style="margin-top: 15px;">
              <button style="margin-left: 10px;" mat-stroked-button (click)="exportTable()">Export</button>
            </div>
            <div style="margin-top: 15px;">
              <button style="margin-left: 10px;" mat-stroked-button (click)="viewSubscribedUsers()">Subscribed Users</button>
            </div>
          </div>
         
          
          
        </div> -->
        

        <mat-tab-group (selectedTabChange)="tabChanged($event)">

          <mat-tab label="Participants">
            <div fxLayout="row" fxFlex="100%" style="margin: 10px;">
              <div class="example-button-row" fxFlex="20%">
                <form [formGroup]="form1">
                  <formly-form [form]="form1" [options]="options1" [model]="model1" [fields]="formFields1">
                  </formly-form>
                </form>
              </div>
              <div fxFlex=10%>
                <button style="margin-top: 15px;" mat-stroked-button (click)="filterPayment1()">Apply</button>
              </div>
              <div fxFlex="70%" fxLayoutAlign="end">
                <div style="margin-top: 15px;">
                  <button style="margin-left: 10px;" mat-stroked-button (click)="generateExcel()">Export</button>
                </div>
              </div>
            </div>
           
            <div fxLayout="row" *ngIf="tableFlag1">
              <table #sort1 id="viewClassParticipantTable" fxFlex="100%" mat-table [dataSource]="participantdataSource" class="mat-elevation-z8"
                matSort matSortActive="created" matSortDisableClear matSortDirection="desc"
                (matSortChange)="resetPaging1()">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggleNew() : null"
                      [checked]="selectionNew.hasValue() && isAllSelectedNew()"
                      [indeterminate]="selectionNew.hasValue() && !isAllSelectedNew()">
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selectionNew.toggle(element) : null" [checked]="selectionNew.isSelected(element)">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="customer_name">
                  <th mat-header-cell *matHeaderCellDef> Paid By</th>
                  <td mat-cell *matCellDef="let element"  [ngStyle]="{'background-color': (!element.userStatus) ? 'red' : ''}"> {{element.customer_name}} </td>
                </ng-container>

                <ng-container matColumnDef="track_id">
                  <th mat-header-cell *matHeaderCellDef> Track ID</th>
                  <td mat-cell *matCellDef="let element"> {{element.track_id}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Status</th>
                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <!-- category Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- product Column -->
                <ng-container matColumnDef="email_id">
                  <th mat-header-cell *matHeaderCellDef> Email ID </th>
                  <td mat-cell *matCellDef="let element"> {{element.email_id}} </td>
                </ng-container>

                 <!-- Name Column -->
                 <ng-container matColumnDef="country_code">
                  <th mat-header-cell *matHeaderCellDef> Country Code </th>
                  <td mat-cell *matCellDef="let element"> {{element.country_code}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="mobile_no">
                  <th mat-header-cell *matHeaderCellDef> Mobile NO </th>
                  <td mat-cell *matCellDef="let element"> {{element.mobile_no}} </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="gender">
                  <th mat-header-cell *matHeaderCellDef> Gender</th>
                  <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
                </ng-container>

                <!-- Phone Column -->
                <ng-container matColumnDef="age">
                  <th mat-header-cell *matHeaderCellDef> Age</th>
                  <td mat-cell *matCellDef="let element"> {{element.age}} </td>
                </ng-container>

                <!-- Payment Ref  Column -->
                <!-- // CR2102043 : More info page pop up is down bring that in proper place (remove few column) Vaccination and Mobile number we need only Alternative work in the pop up. -->

                <!-- <ng-container matColumnDef="vaccination">
                  <th mat-header-cell *matHeaderCellDef> Vaccination</th>
                  <td mat-cell *matCellDef="let element"> {{element.vaccination}} </td>
                </ng-container> -->

            
        

                <ng-container matColumnDef="meeting_status">
                  <th mat-header-cell *matHeaderCellDef>Meeting Status</th>
                  <td mat-cell *matCellDef="let element"> {{element.meeting_status | titlecase}} </td>
                </ng-container>

                 <!-- user type Column -->
                 <ng-container matColumnDef="user_type">
                  <th mat-header-cell *matHeaderCellDef> User Type </th>
                  <td mat-cell *matCellDef="let element"> {{element.user_type |titlecase}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <button  mat-icon-button class="iconbutton" [matMenuTriggerFor]="menu" (click)="editAdditionalInfo(element)">
                      <i class="fas fa-edit" title="Edit"></i>
                    </button>
                    <button *ngIf="model['more_info'] == true"  mat-icon-button class="iconbutton" [matMenuTriggerFor]="menuview" (click)="viewAdditionalInfo(element)">
                      <i class="fas fa-eye" title="View"></i>
                    </button>
                    <button mat-icon-button class="iconbutton" *ngIf="element.status=='SUCCESS'"  (click)="confirmRegisterPart(element)">
                      <i class="fas fa-video" title="Register Meeting"></i>
                    </button>
                    <button [cdkCopyToClipboard]="element.meeting_url" mat-icon-button class="iconbutton"  *ngIf="(element.meeting_status=='Approved' || element.meeting_status=='approved')">
                      <input type="hidden" [(ngModel)]="element.meeting_url">
                      <i class="fas fa-copy" title="Copy URL" ></i>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="participantdisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: participantdisplayedColumns;"></tr>
              </table>
            </div>
            <div fxLayout="row" *ngIf="tableFlag1">
              <mat-paginator #pageNavic2 fxFlex="100%" (page)="participantgetPaginatorData($event)" [length]="participantresultsLength"
                [pageSize]="participantpageSize" [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]"></mat-paginator>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" style="justify-content: flex-end;margin: 20px;">
            
              <button style="margin-left: 10px;" mat-stroked-button (click)="RegisterAllNew()"> Bulk Register</button>
                <button type="button" (click)="enableClassesNew()" mat-stroked-button class="cls-custom-btn"
                color="danger">Enable Participants</button>
              <button type="button" (click)="cancelDialog()" mat-stroked-button class="cls-custom-btn"
                color="danger">Back</button>
              <!-- <button type="submit"  mat-stroked-button class="cls-custom-btn" color="success">Apply</button> -->
      
            </div>
          </mat-tab>

          <mat-tab label="Payment">
            <div fxLayout="row" fxFlex="100%" style="margin: 10px;">
              <div class="example-button-row" fxFlex="20%">
                <form [formGroup]="form">
                  <formly-form [form]="form" [options]="options" [model]="model" [fields]="formFields">
                  </formly-form>
                </form>
              </div>
              <div fxFlex=10%>
                <button style="margin-top: 15px;" mat-stroked-button (click)="filterPayment()">Apply</button>
              </div>
              <div fxFlex="70%" fxLayoutAlign="end">
                <div style="margin-top: 15px;">
                  <button style="margin-left: 10px;" mat-stroked-button (click)="paymentExcelGen()">Export</button>
                </div>
                <!-- <div style="margin-top: 15px;">
                  <button style="margin-left: 10px;" mat-stroked-button (click)="viewSubscribedUsers()">Subscribed Users</button>
                </div> -->
              </div>
            </div>
            <div fxLayout="row" *ngIf="tableFlag">
              <table #sort id="viewClassTable" fxFlex="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                matSort matSortActive="created" matSortDisableClear matSortDirection="desc"
                (matSortChange)="resetPaging()">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                  </td>
                </ng-container>


                <!-- category Column -->
                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef> Category </th>
                  <td mat-cell *matCellDef="let element"> {{element.category}} </td>
                </ng-container>

                <!-- product Column -->
                <ng-container matColumnDef="component_name">
                  <th mat-header-cell *matHeaderCellDef> Product </th>
                  <td mat-cell *matCellDef="let element"> {{element.component_name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="customer_name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email_id">
                  <th mat-header-cell *matHeaderCellDef> Email</th>
                  <td mat-cell *matCellDef="let element"> {{element.email_id}} </td>
                </ng-container>

                <!-- Phone Column -->
                <ng-container matColumnDef="mobile_number">
                  <th mat-header-cell *matHeaderCellDef> Phone</th>
                  <td mat-cell *matCellDef="let element"> {{element.mobile_number}} </td>
                </ng-container>

                <!-- Payment Ref  Column -->
                <ng-container matColumnDef="bank_reference_no">
                  <th mat-header-cell *matHeaderCellDef> Payment Ref</th>
                  <td mat-cell *matCellDef="let element"> {{element.bank_reference_no}} </td>
                </ng-container>

                <!-- Payment status  Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Payment Status</th>
                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <!-- Amount Ref  Column -->
                <ng-container matColumnDef="fee_amount">
                  <th mat-header-cell *matHeaderCellDef> Amount</th>
                  <td mat-cell *matCellDef="let element"> {{element.fee_amount}} </td>
                </ng-container>

                <!-- Date Time Column -->
                <ng-container matColumnDef="creation_timestamp">
                  <th mat-header-cell *matHeaderCellDef>Date Time</th>
                  <td mat-cell *matCellDef="let element"> {{element.creation_timestamp  | date:'medium'}} </td>
                </ng-container>

                <ng-container matColumnDef="meeting_status">
                  <th mat-header-cell *matHeaderCellDef>Meeting Status</th>
                  <td mat-cell *matCellDef="let element"> {{element.meeting_status | titlecase}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button class="iconbutton" *ngIf="element.status=='SUCCESS'" (click)="confirmRegister(element)">
                      <i class="fas fa-video" title="Register Meeting"></i>
                    </button>
                    <button mat-icon-button class="iconbutton" [cdkCopyToClipboard]="element.meeting_url" *ngIf="(element.meeting_status=='Approved' || element.meeting_status=='approved')">
                      <input type="hidden" [(ngModel)]="element.meeting_url">
                      <i class="fas fa-copy" title="Copy URL"></i>
                    </button>

                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

            </div>
            <div fxLayout="row">
              <mat-paginator #pageNavic1 fxFlex="100%" (page)="getPaginatorData($event)" [length]="resultsLength"
                [pageSize]="pageSize" [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]"></mat-paginator>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" style="justify-content: flex-end;margin: 20px;">
              <button type="button" (click)="enableClasses()" mat-stroked-button class="cls-custom-btn"
                color="danger">Enable</button>
      
                <!-- <button type="button" (click)="enableClassesNew()" mat-stroked-button class="cls-custom-btn"
                color="danger">Enable Participants</button> -->
              <button type="button" (click)="cancelDialog()" mat-stroked-button class="cls-custom-btn"
                color="danger">Back</button>
              <!-- <button type="submit"  mat-stroked-button class="cls-custom-btn" color="success">Apply</button> -->
      
            </div>
          </mat-tab>



      
        </mat-tab-group>
      </div>


     


    </div>
  </div>
</section>

<mat-menu #menu="matMenu" class="cls-menu-panel">
  <div style="margin: 18px;" >
    <div>
      <h2>User Information</h2>
     
    </div>
    <div (click)="$event.stopPropagation();">
      <form [formGroup]="form2">
        <formly-form [model]="model2" [fields]="fields2" [options]="options2" [form]="form2">  
          
        </formly-form><br><br>
      </form>
     </div>
     <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5px">
     
      <button class="btn" type="reset" mat-raised-button (click)="$event.preventDefault();">Cancel</button>&nbsp;&nbsp;&nbsp;
      <button type="submit" mat-raised-button color="primary"  (click)="updateAdditionalInfo($event)">Submit</button>
    </div>
  </div>

</mat-menu>


<mat-menu #menuview="matMenu" class="cls-menu-panel-view">
  <div style="margin: 18px;" >
    <div>
      <h2>Additional Information</h2>
    </div>
    <div (click)="$event.stopPropagation();">
      <form [formGroup]="form3">
        <formly-form [model]="model3" [fields]="fields3" [options]="options3" [form]="form3"></formly-form><br><br>
      </form>
      <mat-accordion style="margin-bottom: 10px;color: white !important;">
        <mat-expansion-panel (opened)="panelOpenState = true" style="background-color: white !important;" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              History
            </mat-panel-title>
          </mat-expansion-panel-header>
          <table style="width: 100% !important;" mat-table [dataSource]="participantsHistory" class="mat-elevation-z8">   

       
            <ng-container matColumnDef="age">
              <th mat-header-cell *matHeaderCellDef> Age</th>
              <td mat-cell *matCellDef="let element"> {{element.age}} </td>
            </ng-container>
            <ng-container matColumnDef="gender">
              <th mat-header-cell *matHeaderCellDef> Gender</th>
              <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
            </ng-container>
            
            <!-- // CR2102043 : More info page pop up is down bring that in proper place (remove few column) Vaccination and Mobile number we need only Alternative work in the pop up. -->

            <!-- <ng-container matColumnDef="bloodgrp">
              <th mat-header-cell *matHeaderCellDef> Blood Group </th>
              <td mat-cell *matCellDef="let element"> {{element.blood_group}} </td>
            </ng-container>
            <ng-container>
              <input type="text" placeholder="text">
            </ng-container>
            <ng-container matColumnDef="vaccina">
              <th mat-header-cell *matHeaderCellDef> Vaccination</th>
              <td mat-cell *matCellDef="let element"> {{element.vaccination}} </td>
            </ng-container>
           -->
            <!-- <ng-container matColumnDef="usertype">
              <th mat-header-cell *matHeaderCellDef>User Type</th>
              <td mat-cell *matCellDef="let element"> {{element.user_type}} </td>
            </ng-container> -->
            <ng-container matColumnDef="atrmono">
              <th mat-header-cell *matHeaderCellDef>Alternative Mobile No</th>
              <td mat-cell *matCellDef="let element"> {{element.alternative_country_code}} {{element.alternative_number}} </td>
            </ng-container>
            
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef style="width:170px !important"
              >Address</th>
              <td mat-cell *matCellDef="let element"> {{element.address}}
                <!-- <ul style="list-style-type:none" *ngFor="let i of addressSplit(element.address)">
                  <li>{{i}}</li>
                 
                </ul>  -->
                 </td>
            </ng-container>
            
            <ng-container matColumnDef="newCountryCode">
              <th mat-header-cell *matHeaderCellDef> New Country Code </th>
              <td mat-cell *matCellDef="let element">
                <!-- Assuming the property name in 'element' is 'newCountryCode' -->
                <input formControlName="newCountryCode" placeholder="New Country Code" type="text">
              </td>
            </ng-container>
            <ng-container matColumnDef="newMobileNumber">
              <th mat-header-cell *matHeaderCellDef> New Mobile Number </th>
              <td mat-cell *matCellDef="let element">
                <!-- Assuming the property name in 'element' is 'newMobileNumber' -->
                <input formControlName="newMobileNumber" placeholder="New Mobile Number" type="text">
              </td>
            </ng-container>
            <ng-container matColumnDef="createdTime">
              <th mat-header-cell *matHeaderCellDef>Updated Time</th>
              <td mat-cell *matCellDef="let element"> {{element.updated_time | date:'MM-dd-yyyy HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="logintype">
              <th mat-header-cell *matHeaderCellDef>Login Type</th>
              <td mat-cell *matCellDef="let element"> {{element.loginType}} </td>
            </ng-container>
            <ng-container matColumnDef="loggedinnum">
              <th mat-header-cell *matHeaderCellDef>Logged in Mobile No</th>
              <td mat-cell *matCellDef="let element"> {{element.loggedInMobile}} </td>
            </ng-container>
            <ng-container matColumnDef="loggeduser">
              <th mat-header-cell *matHeaderCellDef>Logged in User</th>
              <td mat-cell *matCellDef="let element"> {{element.loggedInUser}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsHistry"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsHistry;"></tr>
          </table>
        

        </mat-expansion-panel>
   
      </mat-accordion>
      
     
      <!-- <mat-accordion  style="margin-bottom: 10px;color: white !important;">
        <mat-expansion-panel  (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <table>
                <tr>
                  <td style="margin-right: 20px;">
                    <div>
                    
                      <input style="width: 90% !important;" style="color:rgb(55, 54, 54) !important" disabled placeholder="First Name"   value="Karthik K"  type="text" class="cls-input11">
 
                    </div>
                   
                    
                  </td>
                  <td style="margin-right: 20px;">
                    <div>
                   
                      <input style="width: 90% !important;" disabled placeholder="First Name" style="color:rgb(55, 54, 54) !important"   value="karthik123@gmail.com"  type="text" class="cls-input11">
 
                    </div>
                   
                    </td>
                     <td style="margin-right: 20px;"> 
                      <div>
                      
                        <input style="width: 90% !important;" disabled placeholder="First Name" style="color:rgb(55, 54, 54) !important"   value=" 91  8989898978"  type="text" class="cls-input11">
 
                      </div>
                    
                     </td>
                     <td style="margin-right: 20px;">
                    
                    </td>
                </tr>
              </table>
             
            </mat-panel-title>
          
          </mat-expansion-panel-header>
       
       
        
        

        </mat-expansion-panel>
       <br>
      </mat-accordion> -->
     </div>
    
  </div>

</mat-menu>